<template>
  <a-modal width="50%" v-model="visible" title="修改背景" @cancel="cancel" @ok="handleOk">
    <div>
      <g-title>预览背景</g-title>
      <div class="img-div" :style="backUrl ? 'background-image: url(' + backUrl + ');' : ''">
        <div class="weather-card">
          <div v-if="dateData" class="weather-text">
            <div class="weather-text-card">
              <div class="weather-text-card-top">
                <div class="weather-title">{{ dateData.gregorianDay }}</div>
                <div>{{ dateData.gregorianYear + '/' + dateData.gregorianMonth }}</div>
                <div>{{ '农历' + dateData.lunarMonthCn + dateData.lunarDayCn }}</div>
                <div>{{ dateData.weekday }}</div>
              </div>
              <div class="weather-text-card-bottom">
                <span>{{ '双击编辑座右铭' }}</span>
              </div>
            </div>
            <div class="weather-text-card">
              <div class="weather-text-center-top">
                <div v-if="userInfoWeacher.userFile.avatar" class="img-div">
                  <img ref="showImg" :src="userInfoWeacher.userFile.avatar" alt="avatar" class="upload_img" />
                </div>
              </div>
              <div class="weather-text-center-bottom">
                <div>您好，{{ userInfoWeacher.nickName }}</div>
              </div>
            </div>
            <div class="weather-text-card">
              <div class="weather-text-card-top-right">
                <div>
                  <a-icon type="environment" />
                  <span style="margin-left: 8px">武侯区</span>
                </div>
                <div v-if="dateData.now">
                  <span v-if="dateData.now.icon" style="margin-right: 5px">
                    <i :class="'qi-' + dateData.now.icon + ' weather-icon'"></i>
                  </span>
                  <span style="margin-left: 8px" class="weather-title">{{ dateData.now.temp }}°C</span>
                </div>
                <div v-if="dateData.now">
                  <span>{{ dateData.now.text }}</span>
                  <span style="margin-left: 8px">{{ dateData.now.windDir }}</span>
                </div>
              </div>
              <div class="weather-text-card-bottomRight">
                <div>
                  <div class="box" id="mood">
                    <span class="iconfont icon-check-circle icon front"></span>
                  </div>
                  <div style="opacity: 0; height: 0" class="sign">点击打卡</div>
                </div>
                <div class="today-text">
                  <div style="cursor: pointer">点击打卡</div>
                  <div class="sign-num">已累计打卡{{ firstData.user && firstData.user.continuedSignIn }}天</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="margin-top: 10px">
      <g-title>背景列表</g-title>
      <div class="img-list">
        <a-upload
          name="file"
          list-type="picture-card"
          class="avatar-uploader"
          :show-upload-list="false"
          :action="ossUploadUrl"
          :data="ossUploadData"
          :beforeUpload="beforeUpload"
          @change="
            (info) => {
              handleChangeUpload(info, 'cover')
            }
          "
        >
          <div>
            <a-icon :type="avatarLoading ? 'loading' : 'plus'" />
            <div class="ant-upload-text">点击上传</div>
          </div>
        </a-upload>
        <div
          class="img-div"
          :style="
            defaultUrl.isSelect
              ? 'background-image: url(' + defaultUrl.url + ');' + `border:1px solid ${color}`
              : 'background-image: url(' + defaultUrl.url + ');' + 'border:none'
          "
          @click="imgSelect(defaultUrl)"
        ></div>
        <div
          class="img-div"
          :style="
            item.isSelect
              ? 'background-image: url(' + item.url + ');' + `border:1px solid ${color}`
              : 'background-image: url(' + item.url + ');' + 'border:none'
          "
          v-for="item in imgList"
          :key="item.url"
          @click="imgSelect(item)"
        ></div>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { UploadFileToOssMixins } from '@/mixins/UploadFileToOssMixins'
export default {
  mixins: [UploadFileToOssMixins],
  props: {
    userInfoWeacher: {
      type: Object,
      default: () => ({
        userFile: {},
      }),
    },
    color: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      defaultUrl:{
        isSelect:false,
        url:'https://mx2020.oss-cn-beijing.aliyuncs.com/2023-05-18/02fdac89-c070-3646-46aa-07a00a4b0cef_tianqi0jpg',
      },
      visible: false,
      firstData: {},
      dateData: {},
      imgList: [],
      avatarLoading: false,
      backUrl: '',
    }
  },
  methods: {
    imgSelect(item) {
      item.isSelect = true
      this.imgList.forEach((i) => {
        if (i.url != item.url) {
          i.isSelect = false
        }
      })
      this.backUrl = item.url
    },
    handleChangeUpload(info, type) {
      if (info.file.status === 'uploading') {
        this.avatarLoading = true
        return
      }
      if (info.file.status === 'done') {
        let params = this.getSaveFileParams(info.file)
        this.imgList.push({ url: params.filePath, isSelect: false })
        this.removeOssFilePolicy(info.file)
        this.avatarLoading = false
      }
    },
    open(firstData, dateData) {
      this.visible = true
      this.firstData = firstData
      this.dateData = dateData
      this.backUrl=firstData.user.backUrl||''
    },
    handleOk() {
      if (this.backUrl) {
        this.visible = false
        this.$emit('changeBackImg', this.backUrl)
        this.imgList=[]
      } else {
        this.$message.error('请选择背景图片')
      }
    },
    cancel() {
      this.visible = false
      this.imgList=[]
    },
  },
}
</script>

<style lang='less' scoped>
.img-list {
  padding-bottom: 15px;
  .img-div {
    margin-top: 15px;
  }
}
.img-div {
  width: 100%;
  height: 200px;
  border-radius: 10px;
  background-image: url('~@/assets/tianqi0.jpg');
  background-size: 100% 100%;
  background-repeat: repeat;
}
/deep/ .ant-upload {
  min-width: 100% !important;
  min-height: 200px !important;
}
/deep/ .ant-upload-picture-card-wrapper {
  width: 100% !important;
}
.weather-card {
  height: 200px;
  position: relative;
  .weather-text {
    position: absolute;
    padding: 15px 42px 15px 42px;
    top: 0;
    left: 0;
    color: #fff;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    .weather-text-card {
      width: 30%;
      .weather-text-card-top {
        width: 100%;
        height: 60%;
        > div {
          margin-bottom: 3px;
        }
        .weather-title {
          font-size: 1.5rem;
        }
      }
      .weather-text-card-top-right {
        width: 100%;
        height: 60%;
        flex-wrap: wrap;
        > div {
          width: 100%;
          display: flex;
          justify-content: end;
          align-items: center;
          margin-bottom: 10px;
        }
        .weather-title {
          font-size: 1.5rem;
        }
      }
      .weather-text-card-bottom {
        width: 100%;
        height: 30%;
        margin-top: 5%;
        display: flex;
        align-items: end;
        padding-bottom: 20px;
      }
      .weather-text-card-bottomRight {
        width: 100%;
        height: 30%;
        display: flex;
        align-items: end;
        justify-content: end;
        flex-wrap: wrap !important;
        margin-top: 5%;
        .box {
          margin-right: 2px;
        }
        .sign {
          width: 100% !important;
          text-align: center;
          cursor: pointer;
        }
        > div {
          height: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: end;
          > div {
            width: 100%;
            text-align: center;
          }
        }
        .today-text {
          justify-content: end;
          width: 95px;
          > div {
            width: 91px;
          }
          .sign-num {
            color: rgba(0, 0, 0, 0.45);
            text-shadow: 0px 0px 5px #fff;
            font-size: 12px;
          }
        }
      }
      .weather-text-center-top {
        width: 100%;
        height: 60%;
        display: flex;
        justify-content: center;
        align-items: end;
        .img-div {
          width: 90px;
          height: 90px;
          border-radius: 50% !important;
          overflow: hidden;
          img {
            width: auto;
            height: 100%;
          }
        }
      }
      .weather-text-center-bottom {
        width: 100%;
        height: 30%;
        margin-top: 5%;
        text-align: center;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        align-items: end;
        padding-bottom: 20px;
        > div {
          width: 100%;
          margin-top: 3px;
        }
      }
    }
  }
}
.front,
.back {
  span {
    line-height: 50px;
  }
  &::before {
    font-size: 50px;
    line-height: 50px;
  }
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  line-height: 25px;
  text-align: center;
  backface-visibility: hidden; /* 隐藏被旋转的 div 元素的背面 */
}
.box {
  width: 65px;
  height: 65px;
  position: relative;
  transition: all 1s; /* 过渡效果持续0.4秒 */
  transform-style: preserve-3d; /* 为子元素开启 三维立体环境 */
}
.box:hover {
  /* transform: rotateY(540deg);  */
  cursor: pointer;
}
.img-list {
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
}
.img-list::-webkit-scrollbar-button {
  height: 0;
}
.img-list::-webkit-scrollbar {
  border-radius: 0;
  width: 3px;
}
.img-list::-webkit-scrollbar-track {
  border-radius: 0;
}
.img-list::-webkit-scrollbar-track-piece {
  border-radius: 0;
}
.img-list::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #c1c1c1;
}
</style>